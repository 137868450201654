<script>
import FormErrors from '@/util/form-errors'
import errorsMixin from '@/mixins/errors.mixin'
export default {
  mixins: [errorsMixin],
  props: {
    courseID: Number
  },
  
  render() {
    return this.$scopedSlots.default({
      formErrors: this.formErrors,
      send: this.send,
    })
  },

  inject: ['svc'],

  data:() => ({
    processing: false,
    formErrors: new FormErrors()
  }),

  methods: {

    async send(notes) {
      try {
        this.processing = true
        let res = await this.svc.updateCourse(this.courseID, {
          notes: notes
        })
        this.$emit('updated', notes)
        this.$notify({text: 'Notes updated', type: 'success'})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.processing = false
      }
    }
  }
}
</script>